export default class SearchSeeker {
  // static initData (self) {
  //     this.self = self
  // }
  static async fetchAuto(text, update, self, type) {
    if (type == 'original') self.$data.ajax.originalLocationLoad = true;
    else self.$data.ajax.destinationLocationLoad = true;
    text = text.toLowerCase();
    let url =
      'https://autocomplete.travelpayouts.com/places2?locale=en&types=airport,city&term=' +
      encodeURIComponent(text);
    // var myData = [] ;
    await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((resData) => {
        update(resData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  // var classNameAuto = 'bg-dark text-white list-group list-group-flush';
  //eslint-disable-next-line
  static renderAuto(item, currentValue, self) {
    var div = document.createElement('div');
    var i = document.createElement('i');
    var textElement = document.createElement('span');
    textElement.innerText = '(' + item.code + ') ' + item.name;
    if (item.type == 'airport') {
      //1: city, 2 airport
      div.setAttribute('class', 'list-group-item py-0  cur-pointer text-hover-primary ');
      if (item.city_code == self.$data.previousCity) {
        i.setAttribute('class', 'fas fa-plane text-primary ps-3');
      } else {
        i.setAttribute('class', 'fas fa-plane text-primary');
      }
    } else {
      i.setAttribute('class', 'fas fa-city  text-secondary');
      div.setAttribute('class', 'list-group-item py-0  cur-pointer text-hover-secondary ');

      self.$data.previousCity = item.code;
    }
    div.appendChild(i);

    div.appendChild(textElement);
    div.setAttribute('title', item.type);
    return div;
  }

  static renderAutoLimitCountry(item, currentValue, self, countryCodes) {
    if (!countryCodes.includes(item.country_code)) return;
    var div = document.createElement('div');
    var i = document.createElement('i');
    var textElement = document.createElement('span');
    textElement.innerText = '(' + item.code + ') ' + item.name;
    if (item.type == 'airport') {
      //1: city, 2 airport
      div.setAttribute('class', 'list-group-item py-0  cur-pointer text-hover-primary ');
      if (item.city_code == self.$data.previousCity) {
        i.setAttribute('class', 'fas fa-plane text-primary ps-3');
      } else {
        i.setAttribute('class', 'fas fa-plane text-primary');
      }
    } else {
      i.setAttribute('class', 'fas fa-city  text-secondary');
      div.setAttribute('class', 'list-group-item py-0  cur-pointer text-hover-secondary ');

      self.$data.previousCity = item.code;
    }
    div.appendChild(i);

    div.appendChild(textElement);
    div.setAttribute('title', item.type);
    return div;
  }

  static customizeAuto(input, inputRect, container, maxHeight, self, type) {
    if (type == 'original') self.$data.ajax.originalLocationLoad = false;
    else self.$data.ajax.destinationLocationLoad = false;
  }

  static classNameAuto() {
    return 'bg-dark text-white list-group list-group-flush';
  }
}
