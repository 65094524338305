<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <form>
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <ErrorFormField :msgArray="commonErrors" :msg-type="$data.result.status" />
          <div class="mb-3">
            <div class="row">
              <div class="col-md-6">
                <label for="first_name" class="form-label label-require"
                  >First name of main passenger</label
                >
                <div class="input-group">
                  <span class="input-group-text" id="firstNameIcon"
                    ><i class="far fa-user input-icon-swing"></i
                  ></span>
                  <input
                    type="text"
                    id="first_name"
                    class="form-control"
                    placeholder="First name of main people"
                    :value="$data.detail.first_name"
                  />
                </div>
                <ErrorFormField
                  :msgArray="errors.first_name"
                  v-if="errors.first_name !== undefined"
                />
              </div>
              <div class="col-md-6">
                <label for="last_name" class="form-label">Last name of main passenger</label>
                <div class="input-group">
                  <span class="input-group-text" id="lastNameIcon"
                    ><i class="far fa-user input-icon-swing"></i
                  ></span>
                  <input
                    type="text"
                    id="last_name"
                    class="form-control"
                    placeholder="Last name of main people"
                    :value="$data.detail.last_name"
                  />
                </div>
              </div>
            </div>
            <ErrorFormField :msgArray="errors.last_name" v-if="errors.last_name !== undefined" />
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="originalLocation" class="label-require">Original Location</label>

              <div class="input-group">
                <span class="input-group-text">
                  <div
                    class="spinner-border spinner-border-sm text-secondary"
                    role="status"
                    v-if="$data.ajax.originalLocationLoad"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <i class="fas fa-plane-departure text-danger" v-else></i>
                </span>
                <input
                  type="text"
                  class="form-control"
                  id="originalLocation"
                  placeholder="Original Location"
                  autocomplete="off"
                  :value="$data.detail.original_name"
                />
              </div>

              <input type="hidden" id="origin_type" :value="$data.detail.original_type" />
              <input type="hidden" id="origin_latitude" :value="$data.detail.original_latitude" />
              <input type="hidden" id="origin_longitude" :value="$data.detail.original_longitude" />
              <input type="hidden" id="origin_code" :value="$data.detail.original_code" />
              <ErrorFormField
                :msgArray="errors.originalLocation"
                v-if="errors.originalLocation !== undefined"
              />
            </div>
            <div class="col-md-6">
              <label for="originalLocation" class="label-require">Destination Location</label>
              <div class="input-group">
                <span class="input-group-text">
                  <LoadingIcons total="1" v-if="$data.ajax.destinationLocationLoad" />
                  <i class="fas fa-plane-arrival text-danger" v-else></i>
                </span>
                <input
                  type="text"
                  class="form-control"
                  id="destinationLocation"
                  placeholder="Destination Location"
                  autocomplete="off"
                  :value="$data.detail.destination_name"
                />
              </div>

              <input type="hidden" id="destination_type" :value="$data.detail.destination_type" />
              <input type="hidden" id="destination_code" :value="$data.detail.destination_code" />
              <input
                type="hidden"
                id="destination_latitude"
                :value="$data.detail.destination_latitude"
              />
              <input
                type="hidden"
                id="destination_longitude"
                :value="$data.detail.destination_longitude"
              />
              <ErrorFormField
                :msgArray="errors.destinationLocation"
                v-if="errors.destinationLocation !== undefined"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="email" class="form-label label-require">Passenger's Email</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="passengerEmailIcon"
                  ><i class="far fa-envelope input-icon-swing"></i
                ></span>
                <input
                  type="text"
                  id="email"
                  class="form-control"
                  placeholder="Passenger's Email"
                  :value="$data.detail.email"
                />
              </div>
              <ErrorFormField :msgArray="errors.email" v-if="errors.email !== undefined" />
            </div>
            <div class="col-md-6">
              <label for="phone" class="form-label label-require">Passenger's Phone</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="passengerPhoneIcon"
                  ><i class="fa fa-phone input-icon-swing"></i
                ></span>
                <input
                  type="text"
                  id="phone"
                  class="form-control"
                  placeholder="Passenger's Phone"
                  :value="$data.detail.phone"
                />
              </div>
              <ErrorFormField :msgArray="errors.phone" v-if="errors.phone !== undefined" />
            </div>
          </div>
          <div class="mb-3">
            <label for="passengerEmail" class="form-label label-require">
              Upload tickets of flight passengers(.png, .jpg, .jpeg, .gif, .webp, .bmp, .pdf)
            </label>
            <div class="row" v-if="$data.tickets.length > 0">
              <div class="col-md-12 text-center text-primary"><small>Current tickets</small></div>
            </div>
            <div class="row mb-4" v-if="$data.tickets.length > 0">
              <div
                class="col-md-2 text-center"
                v-for="(item, i) in $data.tickets"
                :key="i"
                :id="'col_' + item.id"
              >
                <a :href="'/uploads' + item.file" target="_blank">
                  <img
                    :src="'/uploads' + item.thumb"
                    class="d-block w-100 mb-1"
                    alt="trepr ticket file "
                  />
                </a>
                <a
                  href="javascript:void(0)"
                  class="btn btn-danger btn-sm"
                  title="Do you want to remove this file?"
                  @click="removeTicket(item.id)"
                  ><i class="fa fa-trash"
                /></a>
              </div>
            </div>
            <div class="text-center">
              <div class="input-group mb-3">
                <input
                  type="file"
                  class="form-control px-0 fs-6"
                  id="tikets"
                  name="tikets"
                  accept=".png, .jpg, .jpeg, .gif, .webp, .bmp, .pdf"
                  @change="chooseFile($event)"
                  multiple
                />
                <input type="hidden" id="tikets_base64" />
                <label class="input-group-text px-1">
                  <i class="fa fa-file-upload text-danger"> </i>
                </label>
              </div>
              <input type="hidden" id="number_of_files" />
              <div id="tiketsHidden"></div>
            </div>
            <ErrorFormField :msgArray="errors.tickets" v-if="errors.tickets !== undefined" />
          </div>
          <div class="mb-3 text-center">
            <LoadingIcons v-if="ajax.normal" />
            <button
              class="btn btn-secondary w-25 me-2"
              id="btnLoginModal"
              @click="btnSubmitClick"
              type="button"
              :disabled="ajax.normal"
              v-else
            >
              Submit
            </button>
            <button class="btn btn-primary w-25" type="reset">Reset</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
//eslint-disable-next-line
import Cookies from 'js-cookie';
import MyAutocomplete from '../../search/MyAutocomplete';
// let bootstrap = require('bootstrap');
let autocomplete = require('autocompleter');
export default {
  name: 'PassengerEdit',
  components: { ErrorFormField, LoadingIcons },
  data: function () {
    return {
      commonErrors: [],
      errors: {},
      // formData : {email: "", password: ""},
      ajax: {
        normal: false,
        originalLocationLoad: false,
        destinationLocationLoad: false,
      },
      result: {
        status: '',
      },
      detail: {},
      tickets: [],
    };
  },
  async created() {
    let self = this;
    // console.log("state $store ", this.$store.state)
    let id = this.$route.params.id;
    let apiUrl = process.env.VUE_APP_API_URL;
    let token = this.$store.state.token;
    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    await fetch(apiUrl + 'partner/passenger/detail', {
      method: 'POST',
      headers: headersObject,
      body: 'id=' + encodeURI(id),
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        self.$data.detail = resData.detail;
        self.$data.tickets = resData.tickets;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
  methods: {
    btnSubmitClick: async function () {
      this.$data.ajax.normal = true;
      let apiUrl = process.env.VUE_APP_API_URL;
      var number_of_files = document.getElementById('number_of_files').value;
      let id = this.$route.params.id;
      let data1 = {
        partner_passenger_id: id,
        unique_id: this.$store.state.unique_id,
        first_name: document.getElementById('first_name').value,
        last_name: document.getElementById('last_name').value,
        original_name: document.getElementById('originalLocation').value,
        original_type: document.getElementById('origin_type').value,
        original_code: document.getElementById('origin_code').value,
        original_latitude: document.getElementById('origin_latitude').value,
        original_longitude: document.getElementById('origin_longitude').value,
        destination_name: document.getElementById('destinationLocation').value,
        destination_type: document.getElementById('destination_type').value,
        destination_code: document.getElementById('destination_code').value,
        destination_latitude: document.getElementById('destination_latitude').value,
        destination_longitude: document.getElementById('destination_longitude').value,
        email: document.getElementById('email').value,
        phone: document.getElementById('phone').value,
        number_of_files: number_of_files,
      };
      for (var i = 1; i <= number_of_files; i++) {
        var key = 'data_' + i.toString();
        console.log('key ', key);
        // console.log('value ', document.getElementById(key).value);
        if (document.getElementById(key) == null) {
          key = 'data_' + i.toString();
          console.log('key error ', key);
        }
        data1[key] = document.getElementById(key).value;
      }
      const formData = Object.keys(data1)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
        .join('&');
      let self = this;
      let token = this.$store.state.token;

      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      await fetch(apiUrl + 'partner/passenger/edit', {
        // await fetch(apiUrl + 'partner/passenger/edit?XDEBUG_SESSION_START=PHPSTORM', {
        method: 'POST',
        headers: headersObject,
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('test resData: ', resData);
          // let messages = resData.messages;
          if (resData.status == 'error') {
            self.$data.result.status = 'error';
            // if (messages.common.length > 0) self.$data.commonErrors = messages.common;
            // else self.$data.commonErrors = [];
            //
            // if (messages.email.length > 0) self.$data.emailErrors = messages.email;
            // else self.$data.emailErrors = [];
            //
            // if (messages.password.length > 0) self.$data.passwordErrors = messages.password;
            // else self.$data.passwordErrors.length = 0;
            // console.log('self.$data ', self.$data);
            self.$data.errors = resData.messages;
            return false;
          }
          if (resData.status == 'success') {
            console.log('success updated');
            Cookies.set('actionType', 'updated');
            self.$router.push('/partner/passenger/list');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      this.$data.ajax.normal = false;
    },
    chooseFileClick: function () {
      document.getElementById('tickets').click();
    },
    chooseFile: async function (event) {
      // console.log('type', type);
      // console.log('event', event.target.id);
      // var id = event.target.id;
      var files = event.target.files;
      let limitedUploadSize = parseInt(process.env.VUE_APP_LIMITED_UPLOAD_SIZE);
      let mbSize = limitedUploadSize / 1000000;
      if (files && files[0]) {
        // console.log(this.files[0].size);
        for (var i = 0; i < files.length; i++) {
          if (files[i].size > limitedUploadSize) {
            // alert('We only allow maximum ' + mbSize.toString() + 'per file');
            this.$data.errors.common = [
              'We only allow maximum ' +
                mbSize.toString() +
                'per file. Please choose a smaller file',
            ];
            return false;
          }
        }

        document.getElementById('number_of_files').value = files.length;
        document.getElementById('tiketsHidden').innerHTML = '';
        var index = 1;
        console.log('files ', files);
        for (var j = 0; j < files.length; j++) {
          console.log('test append ' + j.toString(), files[j]['type']);
          var name = files[j]['name'];
          var img = document.createElement('IMG');
          var reader = new FileReader();
          if (files[j] != undefined && files[j]['type'] == 'application/pdf') {
            img.src = '/img/icons/pdf.png';
            img.setAttribute('class', 'img-fluid d-inline-block');
            img.setAttribute('style', 'height: 50px !important');
            img.setAttribute('title', name);
            document.getElementById('tiketsHidden').appendChild(img);
            reader.onload = function (e) {
              var hiddenInput = document.createElement('input');
              hiddenInput.value = e.target.result;
              hiddenInput.setAttribute('type', 'hidden');
              hiddenInput.setAttribute('id', 'data_' + index.toString());
              console.log('test append 1', index);
              index++;
              document.getElementById('tiketsHidden').appendChild(hiddenInput);
            };
            reader.readAsDataURL(files[j]);
            continue;
          }

          reader.onload = function (e) {
            // console.log('test append ii' + j.toString(), e.target);
            var img = document.createElement('IMG');

            img.src = e.target.result;
            img.setAttribute('class', 'img-fluid d-inline-block');
            img.setAttribute('style', 'height: 50px !important');
            img.setAttribute('title', name);
            document.getElementById('tiketsHidden').appendChild(img);
            var hiddenInput = document.createElement('input');
            hiddenInput.value = e.target.result;
            hiddenInput.setAttribute('type', 'hidden');

            hiddenInput.setAttribute('id', 'data_' + index.toString());
            console.log('test append 1', index);
            index++;
            document.getElementById('tiketsHidden').appendChild(hiddenInput);
          };
          reader.readAsDataURL(files[j]);
        }
      }
    },
    async removeTicket(id) {
      console.log('remove ticket id', id);
      // var elem = document.getElementById('col_' + id);
      // elem.parentNode.removeChild(elem);
      // return;
      // let self = this;
      console.log('state $store ', this.$store.state);
      let apiUrl = process.env.VUE_APP_API_URL;
      let token = this.$store.state.token;
      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      await fetch(apiUrl + 'partner/passenger/delete-ticket', {
        method: 'POST',
        headers: headersObject,
        body: 'id=' + encodeURI(id),
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          var elem = document.getElementById('col_' + id);
          elem.parentNode.removeChild(elem);
        })
        .catch(function (err) {
          console.log('error', err);
        });
    },
  },
  computed: {
    currentUrl: function () {
      return window.location.origin + this.$route.fullPath;
    },
  },
  mounted() {
    let self = this;
    let inputOriginalLocation = document.getElementById('originalLocation');
    let inputDestinationLocation = document.getElementById('destinationLocation');

    autocomplete({
      input: inputOriginalLocation,
      minLength: 2,
      disableAutoSelect: true,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'original'),
      className: MyAutocomplete.classNameAuto(),
      // render: MyAutocomplete.renderAuto,
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'original'),
      onSelect: async function (item) {
        let tmpCoordinates = item.coordinates;
        inputOriginalLocation.value = '(' + item.code + ') ' + item.name;
        var type = 0;
        if (item.type == 'airport') {
          type = 2;
        } else {
          type = 1;
        }
        document.getElementById('origin_type').value = type;
        document.getElementById('origin_code').value = item.code;
        document.getElementById('origin_latitude').value = tmpCoordinates.lat;
        document.getElementById('origin_longitude').value = tmpCoordinates.lon;
      },
    });
    // let objCommonAutocomplete =
    autocomplete({
      input: inputDestinationLocation,
      minLength: 2,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'destination'),
      className: MyAutocomplete.classNameAuto(),
      // render: MyAutocomplete.renderAuto,
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'destination'),
      onSelect: async function (item) {
        let tmpCoordinates = item.coordinates;
        inputDestinationLocation.value = '(' + item.code + ') ' + item.name;

        var type = 0;
        if (item.type == 'airport') {
          type = 2;
        } else {
          type = 1;
        }
        document.getElementById('destination_type').value = type;
        document.getElementById('destination_code').value = item.code;
        document.getElementById('destination_latitude').value = tmpCoordinates.lat;
        document.getElementById('destination_longitude').value = tmpCoordinates.lon;
      },
    });
  },
};
</script>

<style scoped></style>
