<template>
  <!--    class="spinner-border text-primary spinner-border-sm"-->
  <div
    role="status"
    v-for="index in parseInt($props.total)"
    :key="index"
    :class="{
      'spinner-border spinner-border-sm': true,
      'text-primary': index % 3 == 0,
      'text-secondary': index % 3 == 1,
      'text-success': index % 3 == 2,
    }"
  >
    <span class="visually-hidden">Loading...{{ index }}</span>
  </div>
</template>

<script>
export default {
  name: "LoadingIcons",
  props: {
    total: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    randomNumber: function (min, max) {
      const r = Math.random() * (max - min) + min;
      return Math.floor(r);
    },
  },
};
</script>

<style scoped></style>
