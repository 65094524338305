<template>
  <div
    :class="{
      'alert py-0 my-1': true,
      'alert-danger': $props.msgType == 'error',
      'alert-success': $props.msgType == 'success',
      'alert-warning': $props.msgType == 'warning',
    }"
    role="alert"
    v-if="msgArray.length > 0"
  >
    <ul class="list-unstyled">
      <li v-for="(item, index) in msgArray" :key="index"><span v-html="item"></span></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ErrorFormField',
  // props: ['msgArray'],
  props: {
    msgArray: {
      type: Array,
      // default: [],
    },
    msgType: {
      type: String,
      default: 'error',
    },
  },
};
</script>

<style scoped></style>
